<template lang="pug">
  div.page-offer-form

    loading(:active.sync="busy", :is-full-page="true")

    form(@submit.prevent="submit", v-if="offer")

      .position-relative
        .buttons.pos-right
          router-link.btn.btn-default(:to="{name:'offer-list'}")
            i.la.la-angle-left
            | Back
          button.btn.btn-primary.ml-2(type="submit", :disabled="!!offer.reserved") Save
            i.la.la-fw.la-save

        b-tabs
          b-tab(title="Offers")
            .row
              .col-sm-6
                .widget
                  .widget-header
                    h1.title {{ offer.id ? 'Edit Complex Offer' : 'Create Complex Offer' }}
                    div.info-buttons
                      router-link(:to="{name:'placement-list', query:{offer_id:offer.id}}")
                        a.btn.btn-circle.btn-primary(v-b-tooltip.hover.bottom, title="Placements")
                          i.la.la-list
                      | &nbsp;
                      router-link(:to="{name:'reports', query:{complex_offer_id:offer.id}}")
                        a.btn.btn-circle.btn-primary(v-b-tooltip.hover.bottom, title="Statistics")
                          i.la.la-bar-chart
                  .widget-body
                    .widget-body
                      .row
                        .col-sm-4
                          .form-group
                            label ID
                            input.form-control(type="text", placeholder="", readonly="readonly", v-model="offer.id || ''")
                        .col-sm-4
                          .form-group
                            label Status
                            select.form-control(v-model="offer.status", name="status")
                              option(v-for="o in statusOptions", :value="o.v") {{ o.t }}
                        .col-sm-4
                          .form-group
                            label Account Manager&nbsp;
                              i.la.la-info-circle(v-b-tooltip.hover.right, title="Assign yourself to receive notifications regarding this offer. See more in Profile Settings")
                            select.form-control(v-model="offer.user_id", name="user")
                              option(:value="null") None
                              option(v-for="o in userOptions", :value="o.v") {{ o.t }}

                      .row
                        .col-sm-12
                          .form-group
                            label Offer Name
                            input.form-control(type="text", placeholder="Name", v-model="offer.name")

                        .col-sm-6
                          .form-group
                            label Start Date
                            b-form-datepicker(v-model="offer.date_start", placeholder="No limit", :state="validDateRange", @input="onDateChange", reset-button)
                        .col-sm-6
                          .form-group
                            label End Date
                            b-form-datepicker(v-model="offer.date_end", placeholder="No limit", :state="validDateRange", @input="onDateChange", reset-button)
              .col-sm-6
                .widget
                  .widget-header
                    h2.title Store Information
                  .widget-body
                    div(style="display: flex;")
                      div(style="flex: 1;")
                        .form-group
                          label Preview URL
                          .input-group
                            input.form-control(type="text", placeholder="Preview URL", v-model="offer.preview_url")
                            .input-group-append
                              button.btn.btn-secondary(type="button", @click="fetchStoreData()")
                                i.la.la-link
                        .form-group
                          label Store Bundle ID
                          input.form-control(type="text", placeholder="com.appname.example", v-model="offer.store_bundle_id")

                        .form-group
                          label Package ID (iOS)
                          input.form-control(type="text", placeholder="12345678", v-model="offer.store_package_id")

                        .form-group
                          b-button-group
                            b-dropdown(no-caret, dropright)
                              template(#button-content)
                                | Auto Fill &nbsp;
                                i.la.la-magic
                              b-dropdown-text Select an offer to fill up the store into
                              b-dropdown-item(v-for="o in connectedOffers", @click="storeAutoFill(o.offer)", v-if="o.offer") {{ o.offer.name }} [{{o.offer.id}}]

                      div(style="padding: 16px 0 0 16px;")
                        div.app-icon-container
                          img(v-if="offer.store_icon", :src="offer.store_icon")
            .widget
              .widget-body
                .form-group
                  label Distribution method
                  select.form-control(v-model="offer.complex_distribution_method")
                    option(value="weights") Weights
                    option(value="first_active") First Active
                    option(value="cr_priority") CR Priority

                table.table.table-bordered.inner-offer-list
                  thead
                    tr
                      td(v-if="offer.complex_distribution_method === 'first_active'") &nbsp;
                      th Advertiser
                      th Offer
                      th Status
                      th Caps
                      th(v-if="offer.complex_distribution_method === 'weights'")
                        | Weight
                        button.btn.btn-outline-dark.ml-1(type="button", @click="resetOffersWeight()", v-b-tooltip.hover.right, title="Reset all weights")
                          i.la.la-times
                      th MMP
                      th Comments
                      td &nbsp;
                  draggable(tag="tbody", :value="connectedOffers", @change="onSort", handle=".drag-handle", ghost-class="ghost", :group="{name: 'offer', pull: 'clone', put: false}")
                    tr(v-if="connectedOffers.length === 0")
                      td(colspan="7") No connected offers
                    tr(v-if="connectedOffers.length > 0", v-for="(o, index) in connectedOffers", v-bind:key="index", :class="{'not-live': o.offer && (o.offer.status !== 'live' || o.weight <= 0 || !o.advertiser.status), 'not-active': o.offer && o.offer.status === 'live' && o.offer.effective_status !== 'active'}")
                      td(v-if="offer.complex_distribution_method === 'first_active'")
                        div.drag-handle
                          svg(width="24px", fill="currentColor", viewBox="0 0 24 24")
                            path(d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z")
                            path(d="M0 0h24v24H0z", fill="none")
                      td
                        multiselect(v-if="o._edit", :multiple="false", :options="advertiserOptions", v-model="o.advertiser",
                          track-by="v", label="t", deselect-label="", select-label="")
                        .input-group(v-if="!o._edit")
                          input.form-control(type="text", placeholder="", readonly="readonly", v-model="o.advertiser.t")
                          .input-group-append(v-if="o.advertiser && !o.advertiser.status")
                            .form-control(v-b-tooltip.hover.bottom, title="This Advertiser has been Paused")
                              i.la.la-warning

                      td
                        multiselect(v-if="o._edit", :multiple="false", :options="o._offerOptions", v-model="o.offer",
                          :limit="1", group-label="name", group-values="items", :group-select="false",
                          track-by="id", label="name", deselect-label="", select-label="",
                          :id="'connected-offer-'+index", @search-change="searchOffer",
                          @select="offerSelected",
                          :loading="o._offerOptionsLoading", :internal-search="false")
                        .input-group(v-if="!o._edit")
                          input.form-control(type="text", placeholder="", readonly="readonly", v-model="'['+o.offer.id+'] '+o.offer.name")
                          span.s2s-badge(v-if="o.offer.s2s") S2S
                          .input-group-append
                            .main-switcher-container
                                i.la.la-link.clickable.main-switcher(v-b-tooltip.hover, title="Super-linked", v-if="o.is_managed_by", @click="o.is_managed_by = !o.is_managed_by")
                                i.la.la-unlink.clickable.main-switcher(v-b-tooltip.hover, title="Unlinked", v-if="!o.is_managed_by", @click="o.is_managed_by = !o.is_managed_by")
                          .input-group-append
                            router-link.btn.btn-secondary(:to="{name:o.offer.type==='complex' ? 'complex-edit' : 'offer-edit', params: {id:o.offer.id}}", target='_blank', v-b-tooltip.hover.right, title="View offer")
                              i.la.la-external-link
                      td
                        .d-flex.justify-content-between
                          b-dropdown(v-if="o.offer", dropright, :variant="statusVariant[o.offer.status]", :text="statusHash[o.offer.status]")
                            b-dropdown-item(:disabled="o.offer.status === 'paused'", href="#", @click="updateStatus(o.offer, 'pause')")
                              i.la.la-pause-circle.mr-2
                              | Pause
                            b-dropdown-item(:disabled="o.offer.status === 'live'", href="#", @click="updateStatus(o.offer, 'live')")
                              i.la.la-play-circle.mr-2
                              | Activate
                            b-dropdown-item(:disabled="o.offer.status === 'cancelled'", href="#", @click="updateStatus(o.offer, 'cancel')")
                              i.la.la-ban.mr-2
                              | Cancel
                          offer-paused-reason(:offer="o.offer")
                      td
                        .caps(v-if="o.offer")
                          div(style="display:flex; width: 100%")
                            div(style="display:50%; flex:1", v-if="!!o.offer.tracking_url || o.offer.type === 'complex'") Cl: {{ o.offer.current_click_count | numberCommas }} / {{ o.offer.daily_click_cap | numberCommas }}
                            div(style="display:50%; flex:1", v-if="!!o.offer.impression_url || o.offer.type === 'complex'") Im: {{ o.offer.current_impression_count | numberCommas }} / {{ o.offer.daily_impression_cap | numberCommas }}
                          //.prog-wrapper(v-if="o.offer.daily_conversion_cap", style="display:flex")
                            div Cv:&nbsp;
                            b-progress(style="width: 100%", :value="o.offer.daily_conversion_cap > 0 ? o.offer.current_conversion_count : 0", :max="o.offer.daily_conversion_cap", show-progress, animated, variant="primary", v-b-tooltip.hover.right, :title="o.offer.current_conversion_count + ' / ' + o.offer.daily_conversion_cap")
                          //div(v-if="!o.offer.daily_conversion_cap") Cv: {{ o.offer.current_conversion_count | numberCommas }} (No limit)
                          div Cv: {{ o.offer.current_conversion_count | numberCommas }} / {{ o.offer.daily_conversion_cap | numberCommas }}

                      td(v-if="offer.complex_distribution_method == 'weights'")
                        input.form-control.input-numeric(v-if="o._edit && o.distribution_level === -1", v-model="o.weight", type="number", @change="calcOffersWeight")
                        priority(v-if="o.distribution_level >= 0", v-model="o.distribution_level", v-on:input="calcOffersWeight")
                        i.la.la-thumbtack(v-if="o.distribution_level === -1 && !o._edit", style="font-size: 22px; display: block", v-b-tooltip.hover.right, title="Fixed weight")
                        b-form-checkbox(v-if="o._edit", v-model="o._custom_weight",  @change="onCustomWeightChange(o)") Fixed
                        span(v-if="!o._edit", :class="{'text-muted': o.distribution_level >= 0}") {{ o.weight }}%

                        // b-progress(v-if="!o._edit", :value="o.weight", :max="100", show-progress, animated, height="2rem")
                      td
                        mmp-icon(v-if="o.offer", :mmp="o.offer.mmp")
                        entity(v-if="o.offer && o.offer.mmp_account_id", type="mmp-account", :id="o.offer.mmp_account_id", :name.sync="mmpAccountHash[o.offer.mmp_account_id]")
                      td
                        quick-edit(v-if="o.offer", field="comments", :r="o.offer", @on-save="updateField", :wrapped="true", type="textarea")
                      td.actions
                        button.btn.mr-2(type="button", :class="{'btn-primary': !o._edit, 'btn-secondary': o._edit}", @click="o._edit = !o._edit", :disabled="!o.offer")
                          i.la(:class="{'la-lock': !o._edit, 'la-unlock': o._edit}")
                        button.btn.btn-danger(type="button", @click="connectedOffers.splice(index, 1); calcOffersWeight()")
                          i.la.la-trash
                  tfoot(v-if="connectedOffers.length > 0")
                    tr
                      th(:colspan="offer.complex_distribution_method === 'weights' ? 3 : 4")
                      th
                        .caps
                          div(style="display:flex; width: 100%")
                            div(style="display:50%; flex:1") Cl: {{ offer.current_click_count | numberCommas }} / {{ offer.daily_click_cap | numberCommas }}
                            div(style="display:50%; flex:1") Im: {{ offer.current_impression_count | numberCommas }} / {{ offer.daily_impression_cap | numberCommas }}
                          //.prog-wrapper(v-if="offer.daily_conversion_cap", style="display:flex")
                            div Cv:&nbsp;
                            b-progress(style="width: 100%", :value="offer.daily_conversion_cap > 0 ? offer.current_conversion_count : 0", :max="offer.daily_conversion_cap", show-progress, animated, variant="primary", v-b-tooltip.hover.right, :title="offer.current_conversion_count + ' / ' + offer.daily_conversion_cap")
                          //div(v-if="!offer.daily_conversion_cap") Cv: {{ offer.current_conversion_count | numberCommas }} (No limit)
                          div Cv: {{ offer.current_conversion_count | numberCommas }} / {{ offer.daily_conversion_cap | numberCommas }}
                      th(colspan="3")


                button.btn.btn-secondary(type="button", @click="addConnectedOffer()") Add

                div(v-if="offer.complex_distribution_method === 'cr_priority'")
                  table.table.table-bordered.mt-4
                    thead
                      tr
                        th
                        th CR Rank
                        th Weight
                        th
                    draggable(tag="tbody", :value="offer.complex_cr_priority_list", @change="onSortCrPriority", handle=".drag-handle")
                      tr(v-for="(priority, index) in offer.complex_cr_priority_list", :key="index")
                        td.drag-handle
                          svg(width="24px", fill="currentColor", viewBox="0 0 24 24")
                            path(d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z")
                            path(d="M0 0h24v24H0z", fill="none")
                        td
                          p {{ index + 1 !== offer.complex_cr_priority_list.length ? index + 1 : 'Others' }}
                        td
                          priority(v-model="priority.distribution_level", @input="calcCrPriorityWeight")
                          span(:class="{'text-muted': priority.weight >= 0}") {{ priority.weight }}%
                        td.actions
                          a.btn.btn-secondary(@click="removeCrPriority(index); calcCrPriorityWeight()")
                            i.la.la-times
                  button.btn.btn-secondary(type="button", @click="addCrPriority()") Add

          b-tab(title="General")
            .row
              .col-md-6
                .widget
                  .widget-header
                    h2.title Campaign Information
                  .widget-body
                    .form-group
                      label Short Description
                      b-input-group
                        b-form-textarea(rows="5", placeholder="Short description", v-model="offer.short_description")
                        b-input-group-append
                          b-button-group
                            b-dropdown(no-caret, dropright)
                              template(#button-content)
                                i.la.la-magic
                              b-dropdown-text Select an offer to fill up this field
                              b-dropdown-item(v-for="o in connectedOffers", @click="autoFill(o.offer, 'short_description')", v-if="o.offer", :disabled="!o.offer.short_description") {{ o.offer.name }} [{{o.offer.id}}]
                    .form-group
                      label Full Description
                      b-input-group
                        b-form-textarea(rows="5", placeholder="Full description", v-model="offer.description")
                        b-input-group-append
                          b-button-group
                            b-dropdown(no-caret, dropright)
                              template(#button-content)
                                i.la.la-magic
                              b-dropdown-text Select an offer to fill up this field
                              b-dropdown-item(v-for="o in connectedOffers", @click="autoFill(o.offer, 'description')", v-if="o.offer", :disabled="!o.offer.description") {{ o.offer.name }} [{{o.offer.id}}]
                    .form-group
                      label User Flow
                      b-input-group
                        b-form-textarea(rows="5", placeholder="User Flow", v-model="offer.user_flow")
                        b-input-group-append
                          b-button-group
                            b-dropdown(no-caret, dropright)
                              template(#button-content)
                                i.la.la-magic
                              b-dropdown-text Select an offer to fill up this field
                              b-dropdown-item(v-for="o in connectedOffers", @click="autoFill(o.offer, 'user_flow')", v-if="o.offer", :disabled="!o.offer.user_flow") {{ o.offer.name }} [{{o.offer.id}}]
                    .form-group
                      label Restrictions / KPI Description
                      textarea.form-control(placeholder="Explain the KPI and restrictions", v-model="offer.kpi")
                    .row
                      .col-sm-6
                        h4.widget-section-title Restriction List
                        .form-group
                          div(v-for="r in restrictionList")
                            b-form-checkbox(v-model="offer.restriction_hash[r.code]") {{ r.label }}
                        .form-group
                          b-button-group
                            b-dropdown(no-caret, dropright)
                              template(#button-content)
                                | Auto Fill &nbsp;
                                i.la.la-magic
                              b-dropdown-text Select an offer to fill up these restrictions
                              b-dropdown-item(v-for="o in connectedOffers", @click="restrictionAutoFill(o.offer)", v-if="o.offer") {{ o.offer.name }} [{{o.offer.id}}]

                      .col-sm-6
                        h4.widget-section-title Additional Restriction Parameters
                        .form-group
                          b-form-checkbox(v-model="offer.allow_incent") Allow Incent
                        .form-group
                          b-form-checkbox(v-model="offer.hard_kpi") Hard KPI



                //.widget
                  .widget-header
                    h2.title Tracking
                  .widget-body
                    .form-group
                      b-form-checkbox(v-model="offer.lock_tracking_url") Lock tracking links
                      b-form-checkbox(v-model="offer.is_direct_link") Is Direct Link
                      b-form-checkbox(v-model="offer.require_validation") Require link validation (affilitest)
                    .form-group
                      url-input(v-model="offer.tracking_url", :macros="urlMacros", label="Click URL", :locked="offer.lock_tracking_url")
                    .form-group
                      div(style="display:flex")
                        label(style="flex:1") Impression URL
                          | &nbsp;
                          a(@click="fetchImpressionLink()")
                            i.fa.fa-link
                      url-input(v-model="offer.impression_url", :macros="urlMacros", label="Impression URL", :locked="offer.lock_tracking_url")

                    .form-group
                      label Custom Fallback URL
                      input.form-control(type="text", v-model="offer.fallback_url")

              .col-md-6
                .widget
                  .widget-header
                    h2.title Caps
                  .widget-body
                    h3.widget-section-title Click & Conversion
                    .form-group
                      b-form-checkbox(v-model="offer.lock_conversion_caps") Lock conversion caps
                      b-form-checkbox(v-model="offer.lock_caps") Lock click & impression caps
                      b-form-checkbox(v-model="offer.hide_caps") Hide all caps
                    .row
                      .col-sm-6
                        .form-group
                          label Daily click cap &nbsp;
                            i.la.la-lock(v-if="offer.lock_caps", v-b-tooltip.hover.right, title="Auto-update disabled")
                            i.la.la-eye-slash(v-if="offer.hide_caps", v-b-tooltip.hover.right, title="Not visible in API")
                          input.form-control(type="text", placeholder="0", v-model="offer.daily_click_cap", readonly="readonly", v-b-tooltip.hover.top, title="Updated automatically via connected offers")
                          // p.form-text Advertiser: {{ advertiser.default_offer_cap }}
                      .col-sm-6
                        .form-group
                          label Daily impression cap &nbsp;
                            i.la.la-lock(v-if="offer.lock_caps", v-b-tooltip.hover.right, title="Auto-update disabled")
                            i.la.la-eye-slash(v-if="offer.hide_caps", v-b-tooltip.hover.right, title="Not visible in API")
                          input.form-control(type="text", placeholder="0", v-model="offer.daily_impression_cap", readonly="readonly", v-b-tooltip.hover.top, title="Updated automatically via connected offers")
                          // p.form-text(v-if="offer.daily_click_cap > 0 && offer.daily_impression_cap === 0") Paused on click cap &nbsp;
                            i.la.la-info-circle(v-b-tooltip.hover.bottom, title="There is no cap on impressions, but once the click cap is reached offer will be paused")
                    .row  
                      .col-sm-6
                        .form-group
                          label Daily conversion cap &nbsp;
                            i.la.la-lock(v-if="offer.lock_conversion_caps", v-b-tooltip.hover.right, title="Auto-update disabled")
                            i.la.la-eye-slash(v-if="offer.hide_caps", v-b-tooltip.hover.right, title="Not visible in API")
                          input.form-control(type="text", placeholder="0", v-model="offer.daily_conversion_cap", readonly="readonly", v-b-tooltip.hover.top, title="Updated automatically via connected offers")
                      .col-sm-6
                        .form-group
                          label Daily install cap &nbsp;
                            i.la.la-eye-slash(v-b-tooltip.hover.right, title="Not visible in API")
                          input.form-control(type="text", placeholder="0", v-model="offer.daily_install_cap", readonly="readonly", v-b-tooltip.hover.top, title="Updated automatically via connected offers")
                      //.col-sm-4
                        .form-group
                          label Restrict C2C
                          input.form-control(type="text", placeholder="0", v-model="offer.restrict_clicks_convert")
                    //.row(v-if="USER.permissions['offers::SUBSOURCE_LIMIT']")
                      .col-sm-6
                        .form-group
                          label Daily Subsource Limit
                          .input-group
                            select.form-control(v-model="dailySubsourceLimitMode")
                              option(value="advertiser") Use Advertiser Default
                              option(value="nolimit") No Limit
                              option(value="custom") Custom
                            input.form-control(type="text", v-model="offer.daily_subsource_limit", v-if="dailySubsourceLimitMode=='custom'")
                          //p.form-text Number of <strong>daily</strong> unique subsources (Advertiser: {{ advertiser.daily_subsource_limit }}).
                      .col-sm-6
                        .form-group
                          label Total Subsource Limit
                          .input-group
                            select.form-control(v-model="totalSubsourceLimitMode")
                              option(value="advertiser") Use Advertiser Default
                              option(value="nolimit") No Limit
                              option(value="custom") Custom
                            input.form-control(type="text", v-model="offer.total_subsource_limit", v-if="totalSubsourceLimitMode=='custom'")
                          // p.help-block Number of <strong>total</strong> unique subsources (Advertiser: {{ advertiser.total_subsource_limit }}).
                    h3.widget-section-title Budget
                    .row
                      .col-sm-6
                        .form-group
                          label Monthly Budget
                          input.form-control(type="text", placeholder="No Limit", v-model="offer.monthly_budget", readonly="readonly", v-b-tooltip.hover.top, title="Updated automatically via connected offers")
                        .form-group(v-if="offer.id && offer.monthly_budget")
                          label Used
                          b-progress(:value="offer.monthly_budget > 0 ? offer.used_monthly_budget : 0", :max="offer.monthly_budget", show-progress, animated, height="2rem")

                      .col-sm-6
                        .form-group
                          label Daily Budget
                          input.form-control(type="text", placeholder="No Limit", v-model="offer.daily_budget", readonly="readonly", v-b-tooltip.hover.top, title="Updated automatically via connected offers")
                        .form-group(v-if="offer.id && offer.daily_budget")
                          label Used
                          b-progress(:value="offer.daily_budget > 0 ? offer.used_daily_budget : 0", :max="offer.daily_budget", show-progress, animated, height="2rem")

                    h3.widget-section-title Frequency Cap
                    frequency-cap(v-model="offer.frequency_cap_params")

                .widget(v-if="USER.config && USER.config.impressionRate")
                  .widget-header
                    h2.title Misc
                  .widget-body
                    .form-group
                      label IP Blacklist
                        small.ml-1 (line separated)
                        i.la.la-info-circle.ml-1(v-b-tooltip.hover.right, title="These IP will be blocked on all offers within this Complex")
                      textarea.form-control(rows="5", placeholder="", v-model="offer.ip_black_list")
                    impression-ratio(v-model="offer.impression_rate")

                .widget
                  .widget-header
                    h2.title Comments (Internal use)
                  .widget-body
                    .form-group
                      //label Comments (Internal use)
                      b-input-group
                        b-form-textarea(rows="3", placeholder="Comments (Internal use)", v-model="offer.comments")
                        b-input-group-append
                          b-button-group
                            b-dropdown(no-caret, dropright)
                              template(#button-content)
                                i.la.la-magic
                              b-dropdown-text Select an offer to fill up this field
                              b-dropdown-item(v-for="o in connectedOffers", @click="autoFill(o.offer, 'comments')", v-if="o.offer", :disabled="!o.offer.comments") {{ o.offer.name }} [{{o.offer.id}}]


          b-tab(title="Targeting")
            .row
              .col-sm-6
                .widget
                  .widget-header
                    h2.title General Settings
                  .widget-body

                    .form-group
                      label Country &nbsp;
                      b-input-group
                        b-form-textarea(rows="3", placeholder="GB, US, ...", v-model="offer.country")

                    .form-group
                      label Platform
                      select.form-control(v-model="offer.platform")
                        option(v-for="o in platformOptions", :value="o.v") {{ o.t }}

                geo-targeting(:params="geoParams", :isReadOnly="true")



              .col-sm-6
                .widget
                  .widget-header
                    h2.title Timeframe restriction
                  .widget-body
                    timeframe-restriction(:params="timeframeParams", v-on:update-timeframe="updateTimeframe")

          b-tab(v-if="!USER.config.supplyManagement", title="Connected Publishers")
            .widget
              .widget-header
                h2.title Connect to publishers
                b-form-checkbox(v-model="showActivePubsOnly") Show active only
              .widget-body
                .row
                  // :disabled="p.id !== null"
                  .col-sm-4(v-for="p in placementStatusList.filter(p => !showActivePubsOnly || p.publisher_status)")
                    .form-text
                      b-form-checkbox(:class="'cstatus-'+p.status", v-model="p.create_placement", v-if="!showActivePubsOnly || p.publisher_status") {{ p.publisher_name }}


          b-tab(v-if="USER.config.supplyManagement && offer.id", title="Supply Management")
            supply-management(
              :offer="offer",
              @report-saved-changes="(value) => isSupplyManagementDataSaved = value"
            )

          b-tab(title="Payout & Events")
            .row
              .col-md-12
                .widget.widget
                  .widget-header
                    h2.title Payout
                    b-button-group
                      b-dropdown(no-caret, dropright)
                        template(#button-content)
                          | Auto Fill &nbsp;
                          i.la.la-magic
                        b-dropdown-text Select an offer to fill up the payout info
                        b-dropdown-item(v-for="o in connectedOffers", @click="payoutAutoFill(o.offer)", v-if="o.offer") {{ o.offer.name }} [{{o.offer.id}}]
                  .widget-body
                    .row
                      .col-sm-4
                        .form-group
                          label Payout Model
                          select.form-control(v-model="offer.payout_type", name="payout_type")
                            option(v-for="o in payoutTypeOptions", :value="o.v") {{ o.v }}
                      .col-sm-4
                        .form-group
                          label Payout
                          input.form-control(type="text", placeholder="Payout", v-model="offer.payout")
                          // b-form-checkbox(v-model="offer.auto_payout") Automatically update payout (API)
                      .col-sm-4
                        .form-group
                          label Currency
                          select.form-control(v-model="offer.currency")
                            option(v-for="o in currencyOptions", :value="o.v", :disabled="o.disabled") {{ o.t }}
            
            .widget
              .widget-header(style="justify-content: initial")
                h2.title Events
              .widget-body
                p Updating events here will affect all connected offers that are super-linked to this complex offer.
                table.event-list.table
                  thead
                    tr
                      th &nbsp;
                      th &nbsp;
                      th.col-event-name Event Name
                        i.la.la-info-circle.ml-1(v-b-tooltip.hover.right, title="Must match the event_name as it reported over the postbacks that are sent by the advertiser/MMP")
                      th.col-event-label Event Label
                      th.col-event-payout Payout
                      th.col-event-payout Publisher Event
                        i.la.la-info-circle.ml-1(v-b-tooltip.hover.right, title="(Optional) Use this to fire a different event name over the publisher postbacks")
                      th.text-center Report
                      th.text-center Once
                      th &nbsp;
                  tbody
                    tr.row-none
                      td
                        div.drag-handle &nbsp;
                      td &nbsp;
                      td.event-list-item
                        span None
                      td.event-list-item
                        span &nbsp;
                      td.event-list-item
                        span 0
                      td &nbsp;
                      td &nbsp;
                      td &nbsp;
                      //td.text-center
                        input(type="radio", v-model="offer.event_idx_conversion_postback", :value="0")
                      //td.text-center
                        input(type="radio", v-model="offer.event_idx_conversion", :value="0")
                      td &nbsp;

                    tr
                      td &nbsp;
                      td.text-center ins.
                      td
                        // span.form-control {{ advertiser.custom_install_event_name }}
                      td
                        span.form-control Install
                      td &nbsp;
                      td
                        span.form-control install
                      td.text-center &nbsp;
                        // input(type="checkbox", v-model="e.report")
                      td.text-center &nbsp;
                        input(type="checkbox", checked="checked", disabled="disabled")
                      td &nbsp;

                  draggable(tag="tbody", v-model="offer.events", handle=".drag-handle", ghost-class="ghost", :group="{name: 'events', pull: 'clone', put: false}")
                    tr(v-for="(e, i) in offer.events")
                      td
                        div.drag-handle
                          svg(width="24px", fill="currentColor", viewBox="0 0 24 24")
                            path(d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z")
                            path(d="M0 0h24v24H0z", fill="none")
                      td.text-center e{{i+1}}
                      td.event-list-item
                        input.form-control(type="text", placeholder="Name", v-model="e.name", :class="{'is-invalid':e.name && e.name.toLowerCase() === 'install'}")
                        .invalid-feedback(v-if="e.name && e.name.toLowerCase() === 'install'") This name is reserved
                      td.event-list-item
                        input.form-control(type="text", placeholder="Label", v-model="e.label")
                      td.event-list-item.multi-inputs
                        select.form-control(v-model="e.payout_mode")
                          option(value="none") None
                          option(value="fixed") Fixed
                          option(value="dynamic") Dynamic
                        .input-group-wrapper
                          .input-group(v-if="e.payout_mode !== 'none'")
                            input.form-control(type="text", placeholder="Payout", v-model="e.payout")
                            .input-group-append
                              span.input-group-text(v-if="e.payout_mode === 'dynamic'") %
                              span.input-group-text(v-if="e.payout_mode === 'fixed'") $
                      td.event-list-item
                        input.form-control(type="text", placeholder="Event name", v-model="e.pub_event_name")
                      td.text-center
                        input(type="checkbox", v-model="e.report")
                      td.text-center
                        input(type="checkbox", v-model="e.unique")
                      td.actions
                        a.btn.btn-secondary(@click="offer.events.splice(i, 1)", href="javascript:void(0)")
                          i.la.la-trash
                p
                  button.btn.btn-secondary(type="button", @click="addEvent()") Add

          b-tab(title="KPI")
            kpi-rules(v-model="offer.rules", :events="offer.events", :generalKpiRules="generalKpiRules",
              :generalCustomKpiRules="generalCustomKpiRules", :offer="offer")

          b-tab(title="Creatives")
            .widget
              .widget-header
                h2.title Creatives
              .widget-body
                .row
                  .col-sm-8
                    table.creative-list.table
                      thead
                        tr
                          th(style="width: 60px") &nbsp;
                          th Name
                          th Type
                          th(style="width: 60px") &nbsp;
                      tbody
                        tr(v-for="(c, i) in offer.creatives")
                          td.text-center
                            a.table-icon(:href="c.url", target="_blank")
                              img(v-if="c.type === 'image'", :src="c.url")
                              .video-placeholder(v-if="c.type === 'video'")
                                i.la.la-play
                          td
                            .name {{ c.name }}
                            small.form-text.text-muted.d-inline(v-if="c.type === 'image'") {{ c.w }}x{{ c.h }}
                            small.form-text.text-muted.d-inline(v-if="c.type === 'video'") {{ c.duration }} sec
                          td {{ c.type }}
                          td.actions
                            a.btn.btn-danger(@click="unselectCreative(i)", href="javascript:void(0)")
                              i.la.la-trash
                  .col-sm-4
                    .row
                      .col-sm-6.form-inline
                        .input-group.input-group-inline.input-group-rounded
                          input.form-control.input-search(type="text", placeholder="Search...", v-model="creativeSearch.filters.keyword", v-debounce:200ms="searchCreatives")
                      .col-sm-6.form-inline
                        .input-group.input-group-inline.input-group-rounded


                    .creative-search-results
                      .no-results.text-center(v-if="creativeSearch.results.length === 0") No creatives matching this search were found...
                      .creative-search-item(v-for="(c, i) in creativeSearch.results", @click="selectCreative(i)")
                        .image-container(v-bind:style="{'background-image': c.type === 'image' ? 'url(\"'+c.url+'\")' : ''}")
                          i.video-icon.la.la-play(v-if="c.type === 'video'")
                        .name {{ c.name }}


                    p.clearfix &nbsp;
                    button.btn.btn-secondary(type="button", @click="addCreative()") Upload New Creative

          b-tab(title="Reports")
            .widget
              .widget-header
                h2.title Reports
              .widget-body
                reports-widget(:date_filters_only="true", :config="{loadOnStart:true}", :offer_id="connectedOffers.filter(o => o.offer).map(o => o.offer.id).join(',')")

          b-tab(title="Activity Log")
            .widget
              .widget-body
                activity-log-table(type="offer", :id="offer.id", :publishers="publishers")


</template>
<style lang="scss">
.multi-inputs {

  &>.input-group-wrapper,
  &>.form-control {
    width: 48%;
    margin: 0 1%;
    display: inline-block;
  }
}

.custom-checkbox {
  &.cstatus-live {
    color: #43bc41;
  }

  &.cstatus-paused {
    color: #2c529b;
  }
}

table.event-list {

  //border: 1px solid #ccc;
  thead {
    tr {
      th {
        padding: 2px 2px;
      }
    }
  }

  tbody {
    tr {
      td {
        padding: 2px 2px;
      }
    }
  }

  .ghost {
    border: 2px dashed #ccc;
  }
}

.page-offer-form {
  .app-icon-container {
    width: 120px;
    height: 120px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
    background: #ccc;
    border-radius: 20px;

    img {
      width: 120px;
      height: 120px;
      border-radius: 20px;
    }
  }
}

.inner-offer-list {
  tr.not-live {
    background: lighten(#dc3545, 40%);

    .form-control,
    .progress {
      background: lighten(#dc3545, 36%);
    }
  }

  tr.not-active {
    background: lighten(#dc7d35, 40%);

    .form-control,
    .progress {
      background: lighten(#dc7d35, 36%);
    }
  }
}

.creative-list.table {
  td {
    .table-icon {
      img {
        height: 28px;
        width: auto;
      }

      .video-placeholder {
        i {
          font-size: 30px;
        }
      }
    }

    &.actions {
      .btn {
        margin-right: 2px;
      }
    }
  }
}

.creative-search-results {
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;

  .no-results {
    height: 60px;
    font-size: 14px;
  }

  .creative-search-item {
    padding: 5px;

    &:hover {
      .image-container {
        border-radius: 5px;
      }

      .name {
        color: #ff945f;
      }
    }

    .image-container {
      transition: all 0.2s ease-in-out;
      width: 80px;
      height: 80px;
      box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
      border-radius: 15px;
      background: #f2f2f2;
      background-position: center;
      background-repeat: no-repeat;
      background-size: auto 100%;

      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;

      img {
        width: 80px;
        height: 80px;
        border-radius: 15px;
      }

      i.video-icon {
        font-size: 50px;
      }
    }

    .name {
      transition: color 0.2s ease-in-out;
      margin-top: 5px;
      color: #29323a;
      width: 80px;
      font-size: 11px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.drag-handle {
  cursor: grab !important;
}

.main-switcher-container {
  display: flex;
  align-items: center;
  background-color: #e9ecef;
  padding: 0 0.25rem;

  .la-link {
    color: var(--primary);
  }

  .la-unlink {
    color: #ccc;
  }

  .main-switcher {
    font-size: 20px;
  }
}

.inner-offer-list tr.not-active .main-switcher-container {
  background: #f7e2d1;
}

.s2s-badge {
  background: #e9ecef;
  font-size: 10px;
  padding: 0 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
<script>
import Vue from 'vue';
import draggable from 'vuedraggable';
import CreativeModal from '../modals/CreativeModal';
import moment from 'moment';
import KpiRules from '../../components/form/KpiRules';
import { copy } from '../../lib/objects';

export default {
  name: 'ComplexForm',
  watch: {
    // Call the method again if the route changes
    $route: 'initializeSearch',
    offer: {
      handler(newValue) {
        if (
          newValue.complex_distribution_method === 'cr_priority'
          && newValue.complex_cr_priority_list.length === 0
        ) {
          this.offer.complex_cr_priority_list = [
            { distribution_level: 5, weight: 30 },
            { distribution_level: 5, weight: 30 },
            { distribution_level: 5, weight: 30 },
            { distribution_level: 2, weight: 10 },
          ];
        }
      },
      deep: true,
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.isSupplyManagementDataSaved) {
      next();
      return;
    }
    
    this.$bvModal.msgBoxConfirm('You have unsaved Supply Management changes. Are you sure you want to leave?').then((value) => {
      if (value) {
        next();
      }
    });
  },
  async beforeRouteEnter(to, from, next) {
    let offerId = +to.params.id;
    let offer = {};
    let advertiser = {};
    let advertiserOptions = (await Vue.ovData.advertiser.getOptions(true))
      .filter((ao) => !ao.t.includes('] Complex'));
    let userOptions = await Vue.ovData.user.getOptions(true, ['admin', 'am']);
    let mmpAccountOptions = await Vue.ovData.mmpAccount.getOptions(true);
    const generalKpiRules = await Vue.ovData.kpiRules.getAll();
    // Copy to prevent changing the original array
    let generalCustomKpiRules = JSON.parse(JSON.stringify(generalKpiRules));
    try {
      if (to.name === 'complex-edit') {
        offer = await Vue.ovData.offer.get(offerId, true);
        advertiser = await Vue.ovData.advertiser.get(offer.advertiser_id, true);
        // Change the active status of the general KPI rules based on the offer's rules.
        // Rules might be overridden by the offer's rules.
        generalCustomKpiRules = generalCustomKpiRules.map((r) => {
          const offerRule = offer.rules.find((or) => or.parent_rule_id === r.id);
          if (!offerRule) {
            return r;
          }

          return {
            ...r,
            active: offerRule.active
          };
        });
      } else {
        offer = Vue.ovData.offer.newInstance();
        offer.type = 'complex';
      }
    } catch (e) {
      Vue.ovNotify.error('Failed to load offer');
      return next(false);
    }
    if (!offer.restrict_timeframe_start) {
      offer.restrict_timeframe_start = '00:00';
    }
    if (!offer.restrict_timeframe_end) {
      offer.restrict_timeframe_end = '23:59';
    }

    Vue.ovData.offer._booleans.forEach((f) => {
      offer[f] = !!offer[f];
    });

    let definitions = await Vue.ovReq.get('settings/getCampaignDefinitions');

    next((vm) => {
      if (offer.type !== 'complex') {
        vm.$router.push({ name: 'offer-edit', params: { id: offer.id } });
      }
      if (offer.type === 'fallback') {
        vm.$router.push({ name: 'offer-fallback' });
      }
      if (!offer.id) {
        offer.user_id = vm.$store.state.user.id;
      }
      vm.draft_offer_ids = to.query.offer_ids || '';
      vm.restrictionList = definitions.restrictions;
      vm.advertiserOptions = advertiserOptions;
      vm.mmpAccountHash = mmpAccountOptions.reduce((acc, o) => {
        acc[o.v] = o.t;
        return acc;
      }, {});
      vm.userOptions = userOptions;
      vm.offer = offer;
      vm.advertiser = advertiser;

      vm.generalCustomKpiRules = generalCustomKpiRules;
      vm.generalKpiRules = generalKpiRules;
      vm.loadPlacementStatus();
      vm.loadConnectedOffers();
      vm.searchCreatives();
      vm.dateRange = {
        startDate: new Date(),
        endDate: new Date(),
      };

      if (offer.daily_subsource_limit === -1) {
        vm.dailySubsourceLimitMode = 'advertiser';
      } else if (offer.daily_subsource_limit === 0) {
        vm.dailySubsourceLimitMode = 'nolimit';
      } else {
        vm.dailySubsourceLimitMode = 'custom';
      }

      if (offer.total_subsource_limit === -1) {
        vm.totalSubsourceLimitMode = 'advertiser';
      } else if (offer.total_subsource_limit === 0) {
        vm.totalSubsourceLimitMode = 'nolimit';
      } else {
        vm.totalSubsourceLimitMode = 'custom';
      }

      if (offerId) {
        vm.graphPerformance = {
          metrics: [
            'revenue',
            'cost',
            'profit',
            'profit_percent',
            'clicks',
            'installs',
            'conversions',
            'reported_conversions',
            'events',
            'reject_events',
          ],
          dimensions: [],
          group_timeframe: 'day',
          date_start: moment().subtract(7, 'days').format('YYYY-MM-DD HH:mm'),
          date_end: moment().format('YYYY-MM-DD HH:mm'),
          sort: ['revenue', 'desc'],
          limit: 10,
          filters: {
            offer_id: [offerId],
          },
        };
      }

      vm.statusHash = vm.statusOptions.reduce((h, o) => {
        h[o.v] = o.t;
        return h;
      });

      vm.timeframeParams = {
        restrict_timeframe_schedule: offer.restrict_timeframe_schedule,
        restrict_timeframe_timezone: offer.restrict_timeframe_timezone,
        restrict_timeframe: offer.restrict_timeframe,
      };

      vm.geoParams = {
        geo_targeting_mode: 'digitalelement',
        targeting_i2l: offer.targeting,
        targeting_de: offer.geo_targeting,
        lock_geo_targeting: true,
      };


      return vm;
    });
  },
  components: {
    KpiRules,
    draggable,
  },
  computed: {
    USER() {
      return this.$store.state.user;
    },
    publishers() {
      return this.placementStatusList.map((p) => ({ id: p.publisher_id, name: p.publisher_name }));
    },
  },
  data() {
    return {
      draft_offer_ids: [],
      timeframeParams: {},
      dailySubsourceLimitMode: 'advertiser',
      totalSubsourceLimitMode: 'advertiser',
      busy: false,
      datepickerConf: {
        format: 'DD/MM/YYYY',
      },
      blwlFields: [
        { v: 'bundle', t: 'Bundle' },
        { v: 'appname', t: 'Appname' },
        { v: 'sub1', t: 'Pub.Subsource' },
        { v: 'adv_sub1', t: 'Adv.Subsource' },
        { v: 'referer', t: 'Referer' },
      ],
      restrictionList: [],
      placementStatusList: [],
      connectedOffers: [],
      offer: null,
      generalCustomKpiRules: [],
      generalKpiRules: [],
      advertiser: null,
      advertiserOptions: [],
      mmpAccountHash: {},
      userOptions: [],
      currencyOptions: Vue.ovData.general.currencyOptions,
      platformOptions: Vue.ovData.general.platformOptions,

      statusOptions: [
        { v: 'draft', t: 'Draft' },
        { v: 'live', t: 'Live' },
        { v: 'paused', t: 'Paused' },
        { v: 'cancelled', t: 'Cancelled' },
        // { v: 'ended', t: 'Ended' }
      ],
      statusHash: {},
      statusVariant: {
        draft: 'secondary',
        live: 'success',
        paused: 'primary',
        cancelled: 'danger',
        ended: 'danger',
      },
      payoutTypeOptions: Vue.ovData.general.payoutTypeOptions,
      creativeSearch: {
        filters: {
          keyword: null,
          app_id: null,
          type: null,
        },
        pagination: {
          total: 0,
          page: 1,
          page_size: 20,
        },
        results: [],
      },
      graphPerformance: null,
      validDateRange: true,
      dateWarning: '',
      editKPI: false,

      rejectedSubsourcesSearch: '',

      selectedTab: 'search',
      advSubsources: [],
      subsourceBucket: [],
      subsourceBucketHash: {},
      isSupplyManagementDataSaved: true,
    };
  },
  methods: {
    updateTimeframe(params) {
      this.offer.restrict_timeframe = params.restrict_timeframe;
      this.offer.restrict_timeframe_timezone = params.restrict_timeframe_timezone;
      this.offer.restrict_timeframe_schedule = params.restrict_timeframe_schedule;
    },
    onDateChange() {
      this.validDateRange =
        !this.offer.date_start ||
        !this.offer.date_end ||
        moment(this.offer.date_start, 'YYYY-MM-DD').isBefore(
          moment(this.offer.date_end, 'YYYY-MM-DD'),
        );
    },

    /** @param {{ moved: {
     *   element: Record<string, any>,
     *   newIndex: number,
     *   oldIndex: number }
     * }} event */
    onSort(event) {
      const { oldIndex, newIndex } = event.moved;
      const arrOldIndex = oldIndex - 1;
      const arrNewIndex = newIndex - 1;
      const newConnectedOffers = copy(this.connectedOffers);
      newConnectedOffers.splice(arrNewIndex, 0, newConnectedOffers.splice(arrOldIndex, 1)[0]);
      for (let index = 0; index < newConnectedOffers.length; index++) {
        newConnectedOffers[index].display_index = index;
      }
      this.connectedOffers = newConnectedOffers;
    },

    autoFill(offer, field) {
      if (!offer[field]) {
        this.$ovNotify.error(`${offer.name} has no data within this field`);
      }
      Vue.set(this.offer, field, offer[field]);
    },

    storeAutoFill(offer) {
      const storeFields = ['preview_url', 'store_bundle_id', 'store_package_id'];
      storeFields.forEach((field) => {
        Vue.set(this.offer, field, offer[field]);
      });
      this.fetchStoreData();
    },

    payoutAutoFill(offer) {
      const payoutFields = ['payout_type', 'payout', 'currency', 'events']; // 'event_convert_payout_only', 'auto_events', 'report_retention',
      payoutFields.forEach((field) => {
        Vue.set(this.offer, field, offer[field]);
      });
    },

    restrictionAutoFill(offer) {
      for (let r of this.restrictionList) {
        Vue.set(this.offer.restriction_hash, r.code, offer.restriction_hash[r.code]);
      }
    },

    offerSelected(o, id) {
      console.log(o, id);
    },

    async searchOffer(query, id) {
      let idx = +id.split('-')[2];
      this.connectedOffers[idx]._offerOptionsLoading = true;
      try {
        let params = {
          keyword: query,
          offer_type: 'normal',
        };
        if (this.connectedOffers[idx].advertiser) {
          params.advertiser_id = this.connectedOffers[idx].advertiser.v;
        }
        console.log(params);
        this.connectedOffers[idx]._offerOptions = await Vue.ovData.offer.getOptions(params);
      } catch (e) {
        console.error(e);
      }
      this.connectedOffers[idx]._offerOptionsLoading = false;
    },

    addConnectedOffer() {
      this.connectedOffers.push({
        _edit: true,
        _custom_weight: false,
        _offerOptions: [],
        _offerOptionsLoading: false,
        weight: 0,
        distribution_level: 3,
        offer: null,
        advertiser: null,
      });
      this.calcOffersWeight();
    },

    async loadConnectedOffers() {
      let resp = {
        records: [],
      };
      if (this.offer.id) {
        resp = await this.$ovReq.get('offer/getConnectedOffers/' + this.offer.id);
      } else {
        let params = { draft_offer_ids: this.draft_offer_ids };
        resp = await this.$ovReq.get('offer/getConnectedOffersDraft', { params });
      }
      this.connectedOffers = [];
      resp.records.forEach((r) => {
        this.connectedOffers.push({
          _offerOptions: [
            {
              id: r.advertiser.id,
              name: `[${r.advertiser.id}] ${r.advertiser.name}`,
              items: [
                {
                  id: r.offer.id,
                  name: `[${r.offer.id}] ${r.offer.name}`,
                },
              ],
            },
          ],
          _offerOptionsLoading: false,
          weight: r.weight,
          distribution_level: +r.distribution_level,
          _custom_weight: r.distribution_level === -1,
          advertiser: {
            v: r.advertiser.id,
            t: `[${r.advertiser.id}] ${r.advertiser.name}`,
            status: r.advertiser.status,
          },
          offer: {
            id: r.offer.id,
            name: `[${r.offer.id}] ${r.offer.name}`,
            ...r.offer,
          },
          is_managed_by: r.is_managed_by,
          _edit: false,
        });
      });
    },

    resetOffersWeight() {
      this.connectedOffers.forEach((o) => {
        Vue.set(o, 'distribution_level', 3);
      });
      this.calcOffersWeight();
    },

    calcOffersWeight() {
      let percentage = 100;
      let priorityOffersTotalWeight = 0;
      this.connectedOffers.forEach((o) => {
        o.distribution_level = +o.distribution_level;
        if (o.distribution_level === -1) {
          percentage -= +o.weight;
        } else {
          priorityOffersTotalWeight += o.distribution_level;
        }
      });
      if (percentage < 0) {
        percentage = 0;
      }
      this.connectedOffers
        .filter((o) => o.distribution_level >= 0)
        .forEach((o) => {
          Vue.set(
            o,
            'weight',
            Math.round((percentage * o.distribution_level) / priorityOffersTotalWeight),
          );
        });
    },

    onCustomWeightChange(innerOffer) {
      innerOffer.distribution_level = innerOffer._custom_weight ? -1 : 3;
      this.calcOffersWeight();
    },

    /** @param {{ moved: {
     *   element: number;
     *   newIndex: number;
     *   oldIndex: number;
     * } }} value */
    onSortCrPriority(value) {
      const { moved: { element, newIndex, oldIndex } } = value;
      /** Delete old element */
      this.offer.complex_cr_priority_list.splice(oldIndex, 1);
      /** Insert new element */
      this.offer.complex_cr_priority_list.splice(newIndex, 0, element);
    },

    addCrPriority() {
      this.offer.complex_cr_priority_list.push({
        distribution_level: 3,
        weight: 0,
      });
      this.calcCrPriorityWeight();
    },

    /** Remove CR Priority @param {number} index */
    removeCrPriority(index) {
      if (this.offer.complex_cr_priority_list.length === 1) {
        return;
      }
      this.offer.complex_cr_priority_list.splice(index, 1);
      this.calcCrPriorityWeight();
    },

    /** Like calcOffersWeight, but for CR Priority */
    calcCrPriorityWeight() {
      let priorityOffersTotalWeight = 0;
      let percentage = 100;
      for (const p of this.offer.complex_cr_priority_list) {
        if (p.distribution_level === -1) {
          percentage -= p.weight;
          continue;
        }
        priorityOffersTotalWeight += p.distribution_level;
      }

      if (percentage < 0) {
        percentage = 0;
      }

      this.offer.complex_cr_priority_list
        .filter((p) => p.distribution_level >= 0)
        .forEach((o) => {
          const newWeight = Math.round((percentage * o.distribution_level) / priorityOffersTotalWeight);
          Vue.set(o, 'weight', newWeight);
        });
    },

    addEvent() {
      this.offer.events.push({
        name: '',
        label: '',
        payout_mode: 'none',
        payout: 0,
      });
    },

    fetchImpressionLink() { },

    async fetchStoreData() {
      const data = {
        url: this.offer.preview_url,
        country: this.offer.country ? this.offer.country.substring(0, 2) : '',
      };
      this.busy = true;
      try {
        let resp = await this.$ovReq.get('offer/getStoreData', { params: data });
        this.offer.store_bundle_id = resp.bundle_id;
        this.offer.store_icon = resp.icon;
        this.offer.store_package_id = resp.package_id;
      } catch (e) {
        console.error(e);
      }
      this.busy = false;
    },

    async loadPlacementStatus() {
      this.busy = true;
      try {
        let resp = await this.$ovReq.get('placement/getOfferStatus/' + this.offer.id);
        this.placementStatusList = resp.placement_status_list;
        this.placementStatusList.forEach((p) => {
          p.create_placement = p.id !== null && p.status === 'live';
        });
      } catch (e) {
        console.error(e);
      }
      this.busy = false;
    },

    async updateStatus(offer, status) {
      if (this.busy) {
        return;
      }
      let data = {
        offer_ids: [offer.id],
      };
      let url = '';
      switch (status) {
        case 'pause':
          url = 'offer/bulkPause';
          break;
        case 'live':
          url = 'offer/bulkActivate';
          break;
        case 'cancel':
          url = 'offer/bulkCancel';
          break;
      }
      if (!url) {
        return this.$ovNotify.error('Invalid action');
      }
      try {
        this.busy = true;
        await this.$ovReq.post(url, data);
        this.$ovNotify.success('Offer has been updated successfully');
        await this.loadConnectedOffers();
      } catch (e) {
        this.$ovNotify.error(e);
      }
      this.busy = false;
    },

    async submit() {
      if (this.busy) {
        return;
      }
      if (this.offer.reserved) {
        return this.$ovNotify.error('You are not authorized to perform this action');
      }
      this.busy = true;
      let data = Vue.util.extend({}, this.offer);

      Vue.ovData.offer._booleans.forEach((f) => {
        data[f] = data[f] ? 1 : 0;
      });
      
      data.connected_offers = [];
      this.connectedOffers.forEach((o) => {
        if (o.offer) {
          data.connected_offers.push({
            offer_id: o.offer.id,
            weight: o.weight,
            distribution_level: o.distribution_level,
            is_managed_by: o.is_managed_by,
          });
        }
      });

      let restrictionList = [];
      for (let k in data.restriction_hash) {
        if (data.restriction_hash[k]) {
          restrictionList.push(k);
        }
      }
      data.restriction_list = restrictionList;

      delete data.black_list;
      delete data.translated_black_list;

      switch (this.dailySubsourceLimitMode) {
        case 'advertiser':
          data.daily_subsource_limit = -1;
          break;
        case 'nolimit':
          data.daily_subsource_limit = 0;
          break;
      }

      switch (this.totalSubsourceLimitMode) {
        case 'advertiser':
          data.total_subsource_limit = -1;
          break;
        case 'nolimit':
          data.total_subsource_limit = 0;
          break;
      }

      try {
        await this.$ovReq.post('offer/save', data);
        this.busy = false;
        this.$ovNotify.success('Offer has been saved');
        this.$router.push({ name: 'offer-list' });
      } catch (e) {
        console.error(e);
        this.busy = false;
      }
    },

    async searchCreatives() {
      this.creativeSearch.busy = true;
      try {
        let params = {
          keyword: this.creativeSearch.filters.keyword,
          page: this.creativeSearch.pagination.page,
          page_size: this.creativeSearch.pagination.page_size,
        };
        let resp = await Vue.ovData.creative.getList(params);
        this.creativeSearch.results = resp.records.filter(
          (r) => !this.offer.creatives.find((c) => c.id === r.id),
        );
        this.creativeSearch.pagination.total = resp.total;
      } catch (e) {
        console.error(e);
      }
      this.creativeSearch.busy = false;
    },

    selectCreative(index) {
      let selected = this.creativeSearch.results.splice(index, 1);
      if (selected.length > 0) {
        this.offer.creatives.push(selected[0]);
      }
    },

    unselectCreative(index) {
      this.offer.creatives.splice(index, 1);
      this.searchCreatives();
    },

    addCreative() {
      let entities = this.offer.creatives;
      this.$modal.show(
        CreativeModal,
        {
          entities,
        },
        {
          height: 'auto',
          scrollable: true,
        },
        {
          'before-close': (/*event*/) => { },
        },
      );
    },

    async updateField(r, field) {
      let data = {
        id: r.id,
        key: field,
        field: field,
        val: r['_' + field],
        value: r['_' + field],
      };
      if (Vue.ovData.offer._booleans.indexOf(field) > -1) {
        data.val = data.val ? 1 : 0;
      }
      try {
        let resp = await this.$ovReq.post('offer/updateField', data);
        r['_edit_' + field] = false;
        r[field] = resp.entity[field];
        this.$ovNotify.success('Record has been updated');
      } catch (e) {
        this.$ovNotify.error(e);
      }
    },
  },
};
</script>
